
//file: index.js
//language: javascript (gatsby)
//Description: this is the page source code
//Happy Love Pre Order Book Guide

//date create : 02/04/2020


// this import library for gatsby and react 
import React from "react"
import { Helmet } from "react-helmet"
import { CheckCircle } from 'react-bootstrap-icons';
import { Player } from 'video-react';
import { Row, Col, Container, Image, Button, Accordion, Card } from "react-bootstrap"
import App from "./Appbook"

// function class {Aplication}, for front page Pre Order Book HLG
class Application extends React.Component {
    render() {
        return (
            <React.Fragment>
                {/* this is title website */}
                <div className="application">
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>Pre Order Book Happy Love Guide</title>
                    </Helmet>
                </div>
                {/* end title website */}
                {/* this section countdown */}
                <App />
                {/* end section countdown */}
                {/* this is section one */}
                <Container fluid className="mrgn-top">
                    <Row className="justify-content-start">
                        <Col lg md sm>
                            <div className="size-image-logo">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%20Logo.png" fluid></Image>
                            </div>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={5} md sm>
                                <div className="size-mockup-book-hlg">
                                    <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                </div>
                            </Col>
                            <Col lg={7} md sm className="d-flex">
                                <div className="text-headline-hlg">

                                    <h2 className="font-sparing-20">
                                        Hemat UP to 60% Untuk Buku
                                    </h2>
                                    <h1 className="font-happy">
                                        Happy Love Guide
                                    </h1>

                                    <p className="font-guide-single">
                                        Panduan Bahagia Mulai Dari Single
                                        <br /> Hingga Bertemu Pria Tepat
                                    </p>

                                    <div className="size-mockup-book-hlg-mobile">
                                        <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                    </div>

                                    <div className="d-flex justify-content-center">

                                        <a href="#order">
                                            <Button className="button-style-sl" size="md">
                                                Ya SAYA MAU COACH!
                                            </Button>
                                        </a>

                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {/* end section one */}
                {/* this is section two */}
                <Container fluid className="bg-sectwo"></Container>
                <Container fluid className="bg-purple">

                    <Container className="text-fast-need">
                        <h2 className="font-fast-need">Seberapa cepat kamu ingin:</h2>

                        <div className="row justify-content-center mt-5">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc"><b>Terhindar dari hubungan "jalanin aja dulu.."</b> tapi gak jelas arah dan tujuannya</p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc">Kuasai teknik <b>"Wanita yang Memilih, Pria yang Mengejar"</b></p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc">Punya <b>mindset yang tepat</b> untuk memulai, menjalani dan menciptakan hubungan yang sehat dan bahagia</p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc">Tau skill, sikap & mindset yang dibutuhkan untuk <b>mendapat pria yang tepat</b></p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc"><b>Tau hal seksi yang bisa memikat pria namun tidak murahan</b></p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc">Punya <b>skill validasi</b> agar memudahkan memilih diantara beberapa yang mendekat dan tidak berlarut-larut dalam proses yang menguras emosi</p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc">Kuasai cara paling efektif bertemu pria yang tepat <b>meskipun kamu gak punya banyak kenalan atau jarang ada waktu bersosialisasi</b></p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc"><b>Punya panduan yang jelas,</b> langkah demi langkah dari single hingga bertemu pria yang tepat</p>
                            </Col>
                        </div>
                        <div className="row justify-content-center">
                            <Col lg={1} md={1} sm={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} md={7} sm={7} xs>
                                <p className="font-desc"><b>Bebas dari hubungan drama,</b> bucin dan semua hubungan romansa yang gak pake logika</p>
                            </Col>
                        </div>
                    </Container>

                </Container>
                {/* end section two */}
                {/* this is section three */}
                <Container>
                    <Row className="wrape-get-ebook justify-content-center">
                        <h2 className="font-get-ebook">
                            Ini Yang Kamu Dapatkan Ketika <br />Pre-Order
                            Exclusive Package
                        </h2>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Buku Happy Love Guide
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Panduan Bahagia Mulai Dari Single Hingga Bertemu Pria Tepat (senilai Rp 499k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dicetak secara <b>FULL COLOR</b></p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dengan tanda tangan penulis</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Mindset #1 yang membuat kamu bahagia
                                    </p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Di.V.A Roadmap, <b>peta lengkap menuju jodoh sejati</b>
                                    </p>
                                </Col>

                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Kuasai teknik "Wanita yang Memilih, Pria yang Mengejar"
                                    </p>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/cheat-sheet.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Cheat Sheet Happy Love Guide
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Rangkuman visual buku Happy Love Guide (senilai Rp 129k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Berisi insight dan teknik penting dalam versi ringkas</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dibuat secara visual sehingga mudah diingat</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Mudah dicontek dan di praktekan dalam kehidupan nyata
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/skil-sheet.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        L.A.D.Y Skill Set Pocket Book
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Kumpulan skill, teknik, dan pembiasaan yang memudahkan Kamu bertemu pria tepat dalam 90 hari atau kurang (senilai Rp 199k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Teknik lengkap dalam setiap fase Di.V.A</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Actionable - tidak hanya mudah dimengerti, tapi juga mendorongmu untuk praktek</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Jadi wanita yang Lovable, Aware, Diverse, dan Youth
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/blue-print.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Love Life Blueprint
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Setiap bangunan kokoh, pasti memiliki rancangan yang matang. Begitu pula dengan khidupan romansa. Miliki panduan untuk merancang kehidupan romansa bahagia (senilai Rp 222k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Kenali diri sendiri - lebih dalam lagi untuk mengetahui kehidupan romansa seperti apa yang akan kamu jalani</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Buat rancangan - kehidupan romansa Kamu mulai dari scratch, dengan setiap kemungkinan dan rencana yang mudah untuk dilakukan</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Bangun hubungan cinta tanpa menebak-nebak dan
                                        <br />kepastian arah
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={6} xs={12}>

                            <Row className="justify-content-center">
                                <Col lg={7} md={7} sm={7} xs={12}>
                                    <div className="image-sec3">
                                        <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                    </div>
                                </Col>
                                <Col lg={9} md={12} sm={12} xs={12}>
                                    <Row className="mt-5">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <h2 className="mockup-ebook-hlg-sec3 text-center">
                                                Exclusive Merchandise
                                            </h2>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <p className="font-size-ebookHLG text-center">Merchandise unik dan menarik yang tidak bisa Kamu dapatkan dimanapun. Hanya bisa Kamu dapatkan dalam paket eksklusif buku ini.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col lg={6} xs={12}>
                            <Row className="justify-content-center">
                                <Col lg={7} md={7} sm={7} xs={12}>
                                    <div className="image-sec3">
                                        <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                    </div>
                                </Col>
                                <Col lg={9} md={12} sm={12} xs={12}>
                                    <Row className="mt-5">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <h4 className="mockup-ebook-hlg-sec3 text-center">
                                                FREE Ongkir Seluruh Indonesia (selama masa pre-order)
                                            </h4>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <p className="font-size-ebookHLG mt-2 text-center">Kamu tinggal di luar negeri? Chat CS kami di nomor WA 081286332715 untuk mendapat perhitungan ongkir.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                </Container>
                {/* end section three */}
                {/* this is section four */}
                <Container fluid className="bg-secFour">
                    <Row className="justify-content-center m-top-sec4 ">
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <div className="w-secfour">
                                <p className="font-style-secfour">
                                    Seberapa sering kamu iri melihat orang lain/pasangan yang membagikan momen bahagianya di sosial media?
                                </p>
                                <p className="font-style-ssec4-middle">
                                    Atau..
                                </p>
                                <p className="font-style-ssec4-middle">
                                    Mungkin kamu tidak iri malahan ikut happy, namun kamu menjadikan kebahagiaan mereka sebagai tolak ukur kebahagiaanmu juga.

                                </p>
                            </div>
                        </Col>
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-women-sec4">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/women.png" fluid></Image>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center mt-5">
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four">
                                Sampai-sampai, jika belum bisa seperti mereka kamu jadi gak happy, sering membanding-bandingkan dan mudah sedih, karena gak punya definisi kebahagiaan versi kamu sendiri.
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Padahal kan, standar kebahagiaan orang lain belum tentu cocok untuk kita…
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Saya jadi berpikir.. <b>Seandainya saja semua wanita punya PANDUAN BAHAGIA,</b> mungkin mereka gak perlu lagi terjebak dalam standar kebahagiaan karir orang lain, standar kebahagiaan romansa orang lain.
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Mereka juga tak perlu lagi buang-buang waktu dengan pria yang salah,
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Terhindar dari hubungan yang gak jelas tujuannya,
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Tau bagaimana menciptakan hubungan yang sehat sekaligus bahagia,
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Mengerti langkah demi langkah mencari pria yang tepat tanpa drama dan air mata.
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Karenanya Saya ingin kamu miliki panduannya, sekarang juga!
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Terutama bagi kamu wanita single yang ingin bertemu lelaki yang tepat, setia, dan
                                <br />tipe kamu banget
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Bagi kamu yang ingin membuka lembaran baru, atau telah terlalu lelah bertemu pria random serta kebahagiaan yang semu.
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3">
                                Di buku ini, Saya akan membahas secara lengkap, luar dan dalam bagaimana caranya agar Kamu <b>bisa bahagia dengan kehidupan romansa, mulai dari kondisi single hingga ketika bertemu pria yang tepat.</b>
                            </p>
                        </Col>
                        <Col lg={8} xs={12}>
                            <p className="text-desc-four mt-3 mb-5">
                                <b>Dan semua dimulai dari <span className="font-guthen"> DIRI KAMU</span>.</b>
                            </p>
                        </Col>
                    </Row>
                </Container>
                {/* section four */}
                {/* this is section five */}
                <Container fluid className="bg-secfive">
                    <Row className="justify-content-center">
                        <Col lg={12} xs={12}>
                            <div className="logo-proofsocial">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testimoni.png" fluid />
                            </div>
                        </Col>
                    </Row>
                    <Container>
                        <Row className="justify-content-center">

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%201.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%206.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2013.jpg"></Image>
                                </div>
                            </Col>

                            {/*
                    <!-- end testi 123-->*/}

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%202.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%208.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%203.jpg"></Image>
                                </div>
                            </Col>

                            {/*
                    <!-- end 456-->*/}

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%204.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%205.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%207.jpg"></Image>
                                </div>
                            </Col>
                            {/*
                    <!--- end 789 -->*/}

                        </Row>
                    </Container>
                </Container>
                <Container fluid className="bg-bottom-sec5"> </Container>
                {/* end section five */}
                {/* this is section six */}
                <Container>
                    <Row className="wrape-get-ebook justify-content-center">
                        <h2 className="font-get-ebook">
                            Ini Yang Kamu Dapatkan Ketika <br />Pre-Order
                            Exclusive Package
                        </h2>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Buku Happy Love Guide
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Panduan Bahagia Mulai Dari Single Hingga Bertemu Pria Tepat (senilai Rp 499k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dicetak secara <b>FULL COLOR</b></p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dengan tanda tangan penulis</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Mindset #1 yang membuat kamu bahagia
                                    </p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Di.V.A Roadmap, <b>peta lengkap menuju jodoh sejati</b>
                                    </p>
                                </Col>

                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Kuasai teknik "Wanita yang Memilih, Pria yang Mengejar"
                                    </p>
                                </Col>

                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/cheat-sheet.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Cheat Sheet Happy Love Guide
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Rangkuman visual buku Happy Love Guide (senilai Rp 129k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Berisi insight dan teknik penting dalam versi ringkas</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Dibuat secara visual sehingga mudah diingat</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Mudah dicontek dan di praktekan dalam kehidupan nyata
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/skil-sheet.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        L.A.D.Y Skill Set Pocket Book
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Kumpulan skill, teknik, dan pembiasaan yang memudahkan Kamu bertemu pria tepat dalam 90 hari atau kurang (senilai Rp 199k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Teknik lengkap dalam setiap fase Di.V.A</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Actionable - tidak hanya mudah dimengerti, tapi juga mendorongmu untuk praktek</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Jadi wanita yang Lovable, Aware, Diverse, dan Youth
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={4} md={4} sm={4} xs={12}>
                            <div className="image-sec3">
                                <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/blue-print.png" fluid></Image>
                            </div>
                        </Col>
                        <Col lg={6} md={6} sm={6} xs={12}>
                            <Row>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <h2 className="mockup-ebook-hlg-sec3">
                                        Love Life Blueprint
                                    </h2>
                                </Col>
                                <Col lg={12} md={12} sm={12} xs={12}>
                                    <p className="font-size-ebookHLG">Setiap bangunan kokoh, pasti memiliki rancangan yang matang. Begitu pula dengan khidupan romansa. Miliki panduan untuk merancang kehidupan romansa bahagia (senilai Rp 222k)</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">
                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Kenali diri sendiri - lebih dalam lagi untuk mengetahui kehidupan romansa seperti apa yang akan kamu jalani</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Buat rancangan - kehidupan romansa Kamu mulai dari scratch, dengan setiap kemungkinan dan rencana yang mudah untuk dilakukan</p>
                                </Col>
                            </Row>
                            <Row className="row justify-content-start">

                                <Col lg={1} md={1} sm={1} xs={1}>
                                    <CheckCircle className="iconB-1" />
                                </Col>
                                <Col lg={11} md={11} sm={11} xs>
                                    <p className="font-desc">Bangun hubungan cinta tanpa menebak-nebak dan
                                        <br />kepastian arah
                                    </p>
                                </Col>
                            </Row>

                        </Col>
                    </Row>

                    <Row className="justify-content-center m-topandbottom">
                        <Col lg={6} xs={12}>

                            <Row className="justify-content-center">
                                <Col lg={7} md={7} sm={7} xs={12}>
                                    <div className="image-sec3">
                                        <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                    </div>
                                </Col>
                                <Col lg={9} md={12} sm={12} xs={12}>
                                    <Row className="mt-5">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <h2 className="mockup-ebook-hlg-sec3 text-center">
                                                Exclusive Merchandise
                                            </h2>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <p className="font-size-ebookHLG text-center">Merchandise unik dan menarik yang tidak bisa Kamu dapatkan dimanapun. Hanya bisa Kamu dapatkan dalam paket eksklusif buku ini.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>

                        </Col>
                        <Col lg={6} xs={12}>
                            <Row className="justify-content-center">
                                <Col lg={7} md={7} sm={7} xs={12}>
                                    <div className="image-sec3">
                                        <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/resize/book-hlg.png" fluid></Image>
                                    </div>
                                </Col>
                                <Col lg={9} md={12} sm={12} xs={12}>
                                    <Row className="mt-5">
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <h4 className="mockup-ebook-hlg-sec3 text-center">
                                                FREE Ongkir Seluruh Indonesia (selama masa pre-order)
                                            </h4>
                                        </Col>
                                        <Col lg={12} md={12} sm={12} xs={12}>
                                            <p className="font-size-ebookHLG mt-2 text-center">Kamu tinggal di luar negeri? Chat CS kami di nomor WA 081286332715 untuk mendapat perhitungan ongkir.</p>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">

                        <Col lg={8} className="bg-circle">
                            <h2 className="text-total-benefit">
                                Total Benefit <br />Exclusive Package  <br /> <span className="size-total-benefit">Rp 999.000</span>
                            </h2>
                        </Col>

                    </Row>

                </Container>
                {/* end section six */}
                {/* this is section order */}
                <Container id="order">
                    <Row className="wrape-get-ebook justify-content-center">
                        <h2 className="font-get-ebook">
                            Untuk Menyelesaikan Order :
                        </h2>
                        <p className="text-benefit mt-3">
                            Yang perlu saya lakukan hanyalah KLIK salah satu TOMBOL HIJAU
                            <br /> yang ada di bawah serta mengisi form order dan mengkonfirmasi pembayaran.
                        </p>
                    </Row>
                </Container>
                <Container className="bg-cover-product">
                    <Row className="justify-content-center">
                        <Col lg={6} xs={12}>
                            <div className="wrapeBook">
                                <h3 className="text-center text-white">Book Only</h3>
                            </div>
                            <div className="mockupBookHlg">
                                <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/PRODUK%20BUKU%20HLG.png"></Image>
                            </div>
                            <div className="pricingBookHlg">
                                <p><del>Rp 499 K</del></p>
                                <h2>Rp 249.000</h2>
                            </div>
                            <div className="d-flex justify-content-center mrgn-bottom-book">

                                <a href="https://JoseAditya.zaxaa.com/o/14606817957317/1">
                                    <Button variant="success" className="btn-book-hlg">Book Only</Button>
                                </a>

                            </div>
                        </Col>
                        <Col lg={6} xs={12}>
                            <div className="wrapeBook">
                                <h3 className="text-center text-white">Exclusive Package</h3>
                            </div>
                            <div className="mockupBookHlg1">
                                <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/PRODUK%20BUKU%20HLG%20plus%20Bonus.png"></Image>
                            </div>
                            <div className="pricingBookHlg">
                                <p><del>Rp 999 K</del></p>
                                <h2>Rp 399.000</h2>
                            </div>
                            <div className="d-flex justify-content-center mrgn-bottom-book">

                                <a href="https://JoseAditya.zaxaa.com/o/14606880157319/1">
                                    <Button variant="success" className="btn-book-hlg">Exclusive Package</Button>
                                </a>

                            </div>
                        </Col>
                    </Row>

                </Container>
                <Container>
                    <Row className="justify-content-center my-5">

                        <h2 className="font-get-ebook text-center text-white mt-4">Pemilik Ebook Cukup Tambah 199K <br /> Untuk Exclusive Package.</h2>

                        <p className="text-benefit mt-3">
                            Hubungi CS disini, lalu infokan nomor invoice Kamu. Kamu akan dikirimkan link khusus
                            <br />untuk harga paling rendah untuk Exclusive Package Happy Love Guide Book
                        </p>
                    </Row>
                    <Row className="justify-content-center">
                        <div className="mrgn-bottom-book">
                            <a href="https://api.whatsapp.com/send?phone=6281286332715&text=Hallo%20CS%2C%20Saya%20sudah%20mempunyai%20Ebook%20HLG%20dan%20mau%20buku%20fisik%20HLG%20bagaimana%20caranya%20ya%20%3F">
                                <Button variant="success" className="btn-book-hlg">CHAT CS DISINI</Button>
                            </a>
                        </div>
                    </Row>
                </Container>
                {/* end section order */}
                {/* this is section eight */}
                <Container fluid className="bg-purple-secEight " id="sec-eight">
                    <Container>
                        <Row className="justify-content-center ">
                            <Col lg={8} xs={12}>
                                <h3 className="text-head-secEight mt-5">
                                    Semakin cepat kamu miliki Happy Love Guide, semakin cepat kamu bisa:
                                </h3>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-5">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg"><b>Terhindar dari hubungan "jalanin aja dulu.."</b> tapi gak jelas arah dan tujuannya</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Kuasai teknik <b>"Wanita yang Memilih, Pria yang Mengejar"</b></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Punya <b>mindset yang tepat</b> untuk memulai, menjalani dan menciptakan hubungan yang sehat dan bahagia</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Tau skill, sikap & mindset yang dibutuhkan untuk <b>mendapat pria yang tepat</b></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg"><b>Tau hal seksi yang bisa memikat pria namun tidak murahan</b></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Punya <b>skill validasi</b> agar memudahkan memilih diantara beberapa yang mendekat dan tidak berlarut-larut dalam proses yang menguras emosi</p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Kuasai cara paling efektif bertemu pria yang tepat <b>meskipun kamu gak punya banyak kenalan atau jarang ada waktu bersosialisasi</b></p>
                            </Col>
                        </Row>
                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg">Punya panduan yang jelas, langkah demi langkah dari single hingga bertemu pria yang tepat</p>
                            </Col>
                        </Row>

                        <Row className="justify-content-center mt-3">
                            <Col lg={1} xs={1}>
                                <CheckCircle className="iconB" />
                            </Col>
                            <Col lg={7} xs>
                                <p className="text-fast-have-hlg"><b>Bebas dari hubungan drama,</b> bucin dan semua hubungan romansa yang gak pake logika</p>
                            </Col>
                        </Row>
                        <div className="d-flex justify-content-center mt-5">
                            <a href="#order">
                                <Button variant="success" className="button-style-order" size="md">
                                    Ya SAYA MAU COACH!
                                </Button>
                            </a>

                        </div>

                    </Container>
                </Container>
                {/* end section eight */}
                {/* this is section nine */}
                <Container fluid className="bg-secNine">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={12} xs={12}>
                                <div className="logo-proofsocial">
                                    <Image src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/testimoni.png" fluid />
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2014.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2015.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%205.jpg"></Image>
                                </div>
                            </Col>
                            {/*
                    <!--- end 101112 -->*/}

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2010.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2018.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2017.jpg"></Image>
                                </div>
                            </Col>
                            {/*
                    <!--- end 131415 -->*/}

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2016.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2019.jpg"></Image>
                                </div>
                            </Col>

                            <Col lg="4" xs={12} className="mt-5">
                                <div className="wrape-text-testi">
                                    <Image className="img img-fluid" src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/HLG%2021.jpg"></Image>
                                </div>
                            </Col>
                            {/*
                    <!--- end 161718 -->*/}
                        </Row>
                    </Container>
                </Container>
                {/* end section nine */}
                {/* this is section FAQ */}
                <Container fluid className="bg-faq">
                    <Container>
                        <Row>
                            <Col lg={12} xs={12} className="faq">
                                <h2>F A Q</h2>
                            </Col>
                        </Row>
                        <Row>

                            <Col lg={12} xs={12}>
                                <Accordion defaultActiveKey="0">
                                    <Card>
                                        <Accordion.Toggle as={Card.Header} eventKey="0">
                                            Apa saja yang dibahas dalam Happy Love Guide?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="0">
                                            <Card.Body>
                                                Lihat apa saja yang bisa Kamu pelajari, <a href="#sec-eight">disini</a>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="1">
                                            Bagaimana cara belinya?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="1">
                                            <Card.Body>
                                                Lihat cara beli, <a href="#order">disini</a>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="2">
                                            Kapan buku fisik dikirim?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="2">
                                            <Card.Body>
                                                Pengiriman akan dimulai 1 Juni 2020 (dikarenakan akhir Mei ada libur lebaran)
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="3">
                                            Bagaimana jika saya berada di luar negeri?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="3">
                                            <Card.Body>
                                                Kamu bisa lakukan pemesanan manual via WhatsApp +6281286332715 (Angel)
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="4">
                                            Saya sudah punya ebooknya, bagaimana cara dapat diskonnya?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="4">
                                            <Card.Body>
                                                Kamu bisa lakukan pemesanan manual via WhatsApp +6281286332715 (Angel) Mohon infokan nomor invoice pembelian Ebook Happy Love Guide kamu
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="5">
                                            Apakah saya masih bisa beli yang versi Ebook?
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="5">
                                            <Card.Body>
                                                Yap, Kamu masih bisa membelinya melalui Aplikasi Love Journey, kunjungi link ini: https://lovecoach.id/apps/love-journey untuk download aplikasinya.
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="6">
                                            Wah jadi gak sabar mau punya buku Happy Love Guide sekarang!
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="6">
                                            <Card.Body>
                                                Yes, saran kami.. Segera putuskan pre order sebelum waktu habis dan kamu menyesal ketinggalan diskonnya.

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                    <Card className="mt-3">
                                        <Accordion.Toggle as={Card.Header} eventKey="7">
                                            Masih ada pertanyaan?

                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey="7">
                                            <Card.Body>
                                                Email kami di cs@joseaccelerated.com atau via WhatsApp 081286332715 (Angel)
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>

                                </Accordion>
                            </Col>

                        </Row>
                    </Container>
                </Container>
                {/* end section faq */}
                {/* this is section step order */}
                <Container fluid className="bg-faq">
                    <Container>
                        <Row>
                            <Col lg={12} xs={12} className="faq">
                                <h2>F A Q</h2>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col lg={6} xs={12}>
                                <Player
                                    playsInline
                                    poster=""
                                    src="https://joseaditya.sgp1.digitaloceanspaces.com/images/lovecoach.id/Happy-Love-Guide/video/Cara%20Beli%20Buku%20Feed.mp4"
                                />
                            </Col>
                        </Row>
                    </Container>
                </Container>
                {/* end step order */}
            </React.Fragment>
        )
    }
}
export default Application